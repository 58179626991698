import React from 'react';
import "./Navbar.css"

function Navbar() {
  return (
    <div className="navbar">
      <div className='textStuff'>
        <h2>Ulubatlı Hasan Anadolu Lisesi</h2>
        <h4 style={{fontSize:"larger"}}>Cosplay Yarışması</h4>
      </div>
    </div>
  )
}

export default Navbar;